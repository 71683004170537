<template>
    <div>

        <body>
            <!-- <div class="responsive"></div> -->
            <!--[if lte IE 9]>
      <p class="browserupgrade">
        You are using an <strong>outdated</strong> browser. Please upgrade your browser</a> to improve
        your experience and security.
      </p>
    <![endif]-->

            <!-- ======== preloader start ======== -->
            <!-- <div class="preloader">
        <div class="loader">
            <div class="spinner">
                <div class="spinner-container">
                    <div class="spinner-rotator">
                        <div class="spinner-left">
                            <div class="spinner-circle"></div>
                        </div>
                        <div class="spinner-right">
                            <div class="spinner-circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
            <!-- preloader end -->

            <!-- ======== header start ======== -->
            <header class="header">
                <div class="navbar-area">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12">
                                <nav class="navbar navbar-expand-lg">
                                    <a class="navbar-brand" href="/#/">
                                        <img src="@/homepage-assets/images/logo/logo.svg" alt="Softinya LMS" />
                                    </a>
                                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="toggler-icon"></span>
                                        <span class="toggler-icon"></span>
                                        <span class="toggler-icon"></span>
                                    </button>

                                    <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                        <ul id="nav" class="navbar-nav">
                                            <!--ms-auto-->
                                            <li class="nav-item">
                                                <a class="" href="/#/">Giriş</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="" href="/#/features">{{
                                                    $t('homepage.whats') }}?</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="" href="#about">{{
                                                    $t('homepage.What_Can_I_Do') }}?</a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="" href="#properties">{{
                                                    $t('homepage.features') }}</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="/#/contact">{{
                                                    $t('homepage.contact') }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- navbar collapse -->

                                    <!-- <a href="#" class="main-btn border-btn btn-hover d-none d-sm-block"><i class="lni lni-calendar"></i> Bir Demo Planlayın</a> -->
                                    <!-- <a style="border:2px solid #fff !important; font-weight:600;" href="/#/login" class="main-btn border-btn btn-hover d-none d-sm-block"><i class="lni lni-arrow-right-circle"></i> Giriş Yap</a> -->

                                </nav>
                                <!-- navbar -->
                            </div>
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <!-- navbar area -->
            </header>
            <!-- ======== header end ======== -->



            <!-- ======== hero-section start ======== -->
            <section class="hero-section v2">
                <div class="container">
                    <div class="row align-items-center position-relative">
                        <div class="col-lg-6 offset-lg-3">
                            <div class="hero-content">
                                <br>
                                <br>
                                <br>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <!-- ======== hero-section end ======== -->


            <!-- ======== properties-section start ======== -->
            <section id="properties" class="properties-section">
                <div class="properties-wrapper container">
                    <div class="row">
                        <div class="col-md-6 offset-md-3 text-center pb-50">
                            <div class="d-flex align-items-center justify-content-centerf flex-column"><img
                                    src="@/homepage-assets/images/logo/logo-2.svg" width="300" alt="Softinya LMS">
                                <div class="section-title">
                                    <h2 class="color-primary">Sözleşme ve Politikalar</h2>
                                </div>
                            </div>
                        </div>

                        <div class="nav-pills-wrapper">
                            <div class="nav flex-column nav-pills me-3 col-md-3 wow fadeInRight" data-wow-delay=".5s"
                                id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <!--<button class="nav-link active" id="v-pills-kullanici-sozlesmesi-tab" data-bs-toggle="pill" data-bs-target="#v-pills-kullanici-sozlesmesi" type="button" role="tab" aria-controls="v-pills-kullanici-sozlesmesi" aria-selected="true"><i class="lni lni-empty-file"></i> Kullanıcı Sözleşmesi</button>-->
                                <button class="nav-link active" id="v-pills-gizlilik-tab" data-bs-toggle="pill"
                                    data-bs-target="#v-pills-gizlilik" type="button" role="tab"
                                    aria-controls="v-pills-gizlilik" aria-selected="true"><i class="lni lni-empty-file"></i>
                                    Gizlilik Politikası</button>
                                <!--<button class="nav-link" id="v-pills-bilgi-guvenligi-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bilgi-guvenligi" type="button" role="tab" aria-controls="v-pills-bilgi-guvenligi" aria-selected="false"><i class="lni lni-empty-file"></i> Bilgi Güvenliği Politikası</button>-->
                                <!--<button class="nav-link" id="v-pills-kvkk-tab" data-bs-toggle="pill" data-bs-target="#v-pills-kvkk" type="button" role="tab" aria-controls="v-pills-kvkk" aria-selected="false"><i class="lni lni-empty-file"></i> KVKK Aydınlatma Metni</button>-->
                                <!--<button class="nav-link" id="v-pills-cerez-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cerez" type="button" role="tab" aria-controls="v-pills-cerez" aria-selected="false"><i class="lni lni-empty-file"></i> Çerez Politikası</button>-->
                            </div>
                            <div class="tab-content col-md-9 v2 wow fadeInLeft " data-wow-delay=".5s"
                                id="v-pills-tabContent" style="height:auto;">
                                <!--<div class="tab-pane fade show active" id="v-pills-kullanici-sozlesmesi" role="tabpanel" aria-labelledby="v-pills-kullanici-sozlesmesi-tab">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                        </div>-->
                                <div class="tab-pane fade show active" id="v-pills-gizlilik" role="tabpanel"
                                    aria-labelledby="v-pills-gizlilik-tab">
                                    <p>Bu gizlilik politikası ile Softinya LMS internet sitesi <span>softinya.com</span>
                                        (Gizlilik politikasında “<span>softinya.com</span>” olarak anılacaktır) üzerinden
                                        kullanıcıların <span>softinya.com</span>’de bulunan kişisel bilgilerinin kullanımı
                                        ve korunması düzenlenmektedir.</p>
                                    <p>Kullanıcıların kişisel bilgileri, adı – soyadı, doğum tarihi, telefon numarası,
                                        e-posta adresi, T.C kimlik numarası gibi kullanıcıyı doğrudan ya da dolaylı olarak
                                        tanımlamaya yönelik her türlü kişisel bilgi olup, bu gizlilik politikasında “kişisel
                                        bilgiler” olarak anılacaktır.</p>
                                    <p><span>softinya.com</span> kullanıcılarının kişisel bilgileri, bu gizlilik politikası
                                        ile belirlenen esas ve kapsam haricinde hiçbir şekilde kullanılmayacak ve ancak
                                        kullanıcının açık rızası ile üçüncü kişilerle paylaşılacaktır.</p>
                                    <p><span>softinya.com</span>, kişisel bilgilerin ve <span>softinya.com</span>
                                        kullanıcılarının gerçekleştirmiş olduğu işlem bilgilerinin güvenliğini ve
                                        gizliliğini sağlamak için gerekli önlemleri almaktadır. <span>softinya.com</span> bu
                                        amaçla, başka programlar kullanabilir. Ancak, <span>softinya.com</span> tarafından
                                        gerekli bilgi güvenliği önlemlerinin almasına karşın internet sitesine ve/veya
                                        sisteme yapılan saldırılar sonucunda kişisel bilgilerin zarar görmesi veya üçüncü
                                        kişilerin eline geçmesi durumunda, <span>softinya.com</span>’un bu nedenle herhangi
                                        bir sorumluluğu olmayacaktır.</p>
                                    <p>Bir kullanıcının, bir başka kullanıcının bilgilerine ulaşması ve bu bilgileri
                                        değiştirmesi mümkün değildir. Kullanıcının kişisel bilgileri,
                                        <span>softinya.com</span> haricinde yalnızca kullanıcı tarafından ulaşılabilir ve bu
                                        bilgiler ancak kullanıcı tarafından güncellenebilir. Kullanıcının talebi ile kişisel
                                        bilgiler <span>softinya.com</span> tarafından da güncellenebilecektir.
                                    </p>
                                    <p><span>softinya.com</span>, kişisel bilgileri ölçümleme çalışmaları, reklam, tanıtım,
                                        pazarlama ile sair iletişim faaliyetleri amacıyla kullanabileceği ve Grup Şirketleri
                                        ile iş ortaklarıyla paylaşabileceği gibi <span>softinya.com</span> tarafından
                                        sunulan hizmet kalitesinin arttırılması ve site faaliyetlerinin daha iyi şekilde
                                        yürütülmesi amacıyla da kayıt altına alınabilir ve kullanabilir.</p>
                                    <p>İdari kurumlar ve adli kurumlar tarafından gelen talepler ve bildirimler
                                        doğrultusunda kişisel bilgiler, Gizlilik Politikasına uygun olarak adli ve idari
                                        kurumlar ile paylaşılabilir.</p>
                                    <p>Kullanıcının kişisel bilgileri; gerçekleştirilen işlemlerde gerekli görülmesi
                                        halinde, sürecin hızlandırılması ve kullanıcılar arasındaki iletişimi sağlamak
                                        amacıyla işleme dahil olan diğer kullanıcı ile paylaşılabilir.</p>
                                    <p><span>softinya.com</span>, internet sayfasını kullanan kullanıcıların istatistiksel
                                        bilgilerini, teknik bir iletişim dosyasını kullanarak elde edebilir. Kullanıcılar
                                        isterse, bu dosyayı engellemeye yönelik tarayıcı ayarlarını değiştirebilirler.</p>
                                    <p><span>softinya.com</span> internet sitesini ziyaret eden her kullanıcı,
                                        <span>softinya.com</span> gizlilik politikası hükümlerini kabul etmiş sayılacaktır.
                                    </p>
                                </div>
                                <!--<div class="tab-pane fade" id="v-pills-bilgi-guvenligi" role="tabpanel" aria-labelledby="v-pills-bilgi-guvenligi-tab">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                        </div>
                        <div class="tab-pane fade" id="v-pills-kvkk" role="tabpanel" aria-labelledby="v-pills-kvkk-tab">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                        </div>
                        <div class="tab-pane fade" id="v-pills-cerez" role="tabpanel" aria-labelledby="v-pills-cerez-tab">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, suscipit ipsum ad libero, illum, aliquam facilis rem nulla dolorum provident ab odit? Omnis saepe fuga natus error ea molestiae perspiciatis?</p>
                        </div>-->


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ======== properties-section end ======== -->

            <!-- ======== footer start ======== -->
            <footer class="footer ">
                <div class="container ">
                    <div class="widget-wrapper ">
                        <div class="row ">
                            <div class="col-xl-4 col-lg-4 col-md-6 ">
                                <div class="footer-widget ">
                                    <div class="logo mb-30 ">
                                        <a href="index.html ">
                                            <img src="@/homepage-assets/images/logo/logo.svg" alt="Softinya LMS" />
                                        </a>
                                    </div>
                                    <p class="desc mb-30 text-white ">
                                        <a href="mailto:info@softinya.com"><i class="lni lni-envelope"></i>
                                            info@softinya.com</a>
                                        <a href="tel:0(212)-267-53-73"><i class="lni lni-phone"></i> (0212) 267 53 73</a>
                                        <a href="tel:0(534)-522-08-59"><i class="lni lni-whatsapp"></i> (0534) 522 08 59</a>
                                    </p>
                                </div>
                            </div>

                            <div class="col-xl-2 col-lg-2 col-md-6 ">
                                <div class="footer-widget mt-25">
                                    <ul class="links ">
                                        <li><a href="/#/">Giriş</a></li>
                                        <li><a href="#features">{{
                                            $t('homepage.whats') }}?</a></li>
                                        <li><a href="#about">Neler Yapabilirim?</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-3 col-md-6 ">
                                <div class="footer-widget mt-25">
                                    <ul class="links ">
                                        <li><a href="#properties">Özelikler</a></li>
                                        <li><a href="/#/contact">İletişim</a></li>
                                        <li><a href="/#/policies">Sözleşmeler ve Politikalar</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-3 col-md-6 ">
                                <div class="footer-widget ">
                                    <ul class="socials mt-80">
                                        <li>
                                            <a target="_blank" href="https://www.instagram.com/anzeraofficial">
                                                <i class="lni lni-instagram-filled "></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank"
                                                href="https://www.linkedin.com/company/anzeraogrenmeteknolojileri">
                                                <i class="lni lni-linkedin-original "></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
            <!-- ======== footer end ======== -->

            <!-- ======== scroll-top ======== -->
            <a href="# " class="scroll-top btn-hover ">
                <i class="lni lni-chevron-up "></i>
            </a>

        </body>


    </div>
</template>
<script>

export default {
    name: "policies",

    computed: {
    },

    methods: {
    },
    mounted() {

    }
};
</script>
<style scoped>
/* <link rel="stylesheet" href="@/homepage-assets/css/bootstrap.min.css" />
    <link rel="stylesheet" href="@/homepage-assets/css/lineicons.css" />
    <link rel="stylesheet" href="@/homepage-assets/css/animate.css" />
    <link rel="stylesheet" href="@/homepage-assets/css/main.css" />
    <link rel="stylesheet" href="@/homepage-assets/css/custom.css" /> */
@import '../../homepage-assets/css/bootstrap.min.css';
@import '../../homepage-assets/css/lineicons.css';
@import '../../homepage-assets/css/animate.css';
@import '../../homepage-assets/css/main.css';
@import '../../homepage-assets/css/custom.css';
</style>
